.container {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.container::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;

  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all 320ms ease-in-out;
  background-color: rgba(16, 16, 15, 0.15);
}

.container::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;

  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(220deg, rgba(0, 0, 0, 0) 0%, black 100%);
}

.linksContainer {
  z-index: 2;
  position: relative;
}

.linksContainer::after {
  position: absolute;
  content: " ";
  top: 0.8rem;
  left: -0.8rem;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #333;
}

.link {
  max-width: 290px;
  min-height: 2rem;
  z-index: 2;
  position: relative;
  transition: all 320ms ease-in;
}

.link:hover {
  background: #888;
}


@media screen and (min-width: 790px) {
  .link {
    max-width: 320px;
  }
}

.presentationOne {
  content: " ";
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
  width: 1px;
  height: 24rem;
  position: fixed;
  left: -20%;
  bottom: 0;
  z-index: 2;
  transform: rotate(-90deg) translate3d(0, 100%, 0);
}

.presentationTwo {
  content: " ";
  display: block;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
  width: 1px;
  height: 7.8rem;
  position: absolute;
  left: 30%;
  top: 0;
}

@media screen and (min-width: 768px) {
  .presentationOne {
    left: -40%;
  }
}

@media screen and (min-width: 1220px) {
  .presentationOne {
    left: -20%;
  }
}